
import CardHistoryItem from "./Cardhistoryitem";
import MyHistoryLectures from "./MyHistoryLectures";
import MiPuntaje from "./MiPuntaje";
function Myprogress({ user}) {

    return (
      <>

      <div class="grid grid-cols-1 mb-4 sm:grid-cols-1 lg:grid-cols-1 gap-4">
        <div class="bg-gray-200 p-12">Columna 1</div>
      </div>
      
      <MiPuntaje  user={user}></MiPuntaje>

      <div class="grid grid-cols-1 mb-4 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <div class="bg-gray-200 p-4">Columna 1</div>
        <div class="bg-gray-200 p-4">
              
        </div>
        <div class="bg-gray-200 p-4">Columna 4</div>
        <div class="bg-gray-200 p-4">Columna 5</div>
      </div>

      <div class="grid grid-cols-1 mb-4 sm:grid-cols-1 lg:grid-cols-1 gap-4">

        <div class="bg-gray-200 p-4">
            <MyHistoryLectures user={user}></MyHistoryLectures>
        </div>

      </div>
      

      </>

  


        
      );

    
}

export default Myprogress