import { useEffect, useState } from "react";



export default function GetLectoresPuntos({url,inputdata,method,user}) {
  const [isLoading, setIsLoading] = useState(true);
  const [listaLecturas, setListaLecturas] = useState(null);
  const [error, setError] = useState(null);

  console.log(url)

  useEffect(() => {
    if (isLoading) {
      async function fetchData() {
        let response
        try {

          if(method === 'get'){
            response = await fetch(
              url
            );
          }else if(method === 'post'){
            response = await fetch(
              url,
              inputdata
            );
          }
          
          

          if (response.ok) {
            const lista = await response.json();
            setListaLecturas(
                lista
            );
           
            
            setError(null);
            setIsLoading(false);
          } else {
            setError("Hubo un error al obtener la lista de lecturas.");
          }
        } catch (error) {
            console.log("--------------------------------------")
          console.log(error)
          setError("No pudimos hacer la solicitud para obtener las lecturas. Conéctate a la red.");
        }
      }
      fetchData();
    }
  }, [isLoading]);

  const listaLecturaLoader = () => {
    setIsLoading(true);
  };


  var da = []
  if (error) { // ⬅️ mostramos el error (si es que existe)
    return (
         <div>Error: {error}</div>
    );
  }
  var transformedStudents = []
  var filteredWithRankings = {}
  if (!listaLecturas) {
    return <div>No data available</div>;
  }else{

    da = JSON.parse(listaLecturas[0].body).Items

    

    var _transformedStudents = da.map(student => {
      return {
        name: [`@${student.given_name.split(" ")[0].toLowerCase()}_${student.family_name.split(" ")[0].toLowerCase()}`, `link_profile_${student.usuario_id}`],
        puntaje: student.puntaje_acumulado,
        colegio: `${student.colegio}@${student.locale}`,
        rating: student.lecture_id_distintos,
        categoria: `${student.categoria_usuario}@${student.nivel}`,
        usuario_id: student.usuario_id
      };
    });

    transformedStudents = _transformedStudents.sort((a, b) => b.puntaje - a.puntaje);



    console.log(transformedStudents);
    filteredWithRankings = transformedStudents.filter(item => item.usuario_id === user.username)
    .map((item, index) => ({
        ...item,
        ranking: index + 1
    }));

    if (filteredWithRankings.length === 0) {
        filteredWithRankings = [{
          puntaje_acumulado: 0,
          lecture_id_distintos: 0,
          usuario_id: user.usuario_id,
          ranking: null,
          message: 'No records found for this user.'
        }];
    }

        console.log(filteredWithRankings);
  }


    return (

        <>
            <div class="grid grid-cols-2 mb-4 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                
                <div class="bg-gray-200 p-4">{filteredWithRankings[0].puntaje}</div>
                <div class="bg-gray-200 p-4">{filteredWithRankings[0].rating}</div>
                <div class="bg-gray-200 p-4">{filteredWithRankings[0].ranking}</div>

            </div>
        </>
        
   ) 
}