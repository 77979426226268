import { useEffect, useState } from "react";

export default function Fetchdatos(url,inputdata,method) {
  const [isLoading, setIsLoading] = useState(true);
  const [listaLecturas, setListaLecturas] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isLoading) {
      async function fetchData() {
        let response
        try {

          if(method === 'get'){
            response = await fetch(
              url
            );
          }else if(method === 'post'){
            response = await fetch(
              url,
              inputdata
            );
          }
          
          

          if (response.ok) {
            const lista = await response.json();
            setListaLecturas(
                lista
            );
           
            
            setError(null);
            setIsLoading(false);
          } else {
            setError("Hubo un error al obtener la lista de lecturas.");
          }
        } catch (error) {
          console.log(error)
          setError("No pudimos hacer la solicitud para obtener las lecturas. Conéctate a la red.");
        }
      }
      fetchData();
    }
  }, [isLoading]);

  const listaLecturaLoader = () => {
    setIsLoading(true);
  };

 

  if (error) { // ⬅️ mostramos el error (si es que existe)
    return (
      <div className="App">
        
        
        <div className='card-punctuacion-message'>
        <h1>{error}</h1>
        <a href={`/`} className='btn btn-outline-secondary rounded-2'>
                Volver a intentarlo
        </a>
        </div>
      </div>
    );
  }

  return listaLecturas;
}