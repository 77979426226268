

import { Logo } from "./Logo";

function FooterLoad({ title = "" }) {
    return (
      <footer>
        <a href="/" >
          <Logo />
        </a>
        <a
          href="#"
        >
          <code style={{fontSize:'1.2rem'}}>{title}</code>
        </a>
      </footer>
    );
  }

function Footer() {
    return (
        <div>
            
            <FooterLoad title="Lecturas que Florecen" ></FooterLoad>
        </div>
    )
}

export default Footer