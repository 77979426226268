import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions.tsx";
import { MenuToggle } from "./MenuToggle.tsx";
import { Navigation } from "./Navigation.tsx";

const sidebar = {
  open: (height = 300) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export const Sidemenu = ({SignOut, username}) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav
    initial={false}
    animate={isOpen ? "open" : "closed"}
    custom={height}
    ref={containerRef}
  >
    <motion.div
      className="background"
      style={{ position: 'fixed', top: 0, left: 0, width: '245px', zIndex: 50 }}
      variants={sidebar}
    >
      <MenuToggle toggle={() => toggleOpen()} />
      <Navigation SignOut={SignOut} username={username} />
    </motion.div>
  </motion.nav>
  

  )

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      
      <motion.div className="background  sticky top-1 z-2" variants={sidebar} >
      
      <MenuToggle toggle={() => toggleOpen()} />
      <Navigation SignOut={SignOut} username={username}/>
      </motion.div>
    </motion.nav>
  );
};
