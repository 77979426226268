import Cards from "../components/Cards";
import PublicS3Reader from '../components/s3/PublicS3Reader.js'

export default function First({ user, signOut }) {
  return (
    <div className='App'>
      <div className="bg-cream-500 py-0 px-16">
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center text-center">
            
            {/* Texto principal */}
            <div className="flex justify-between items-center">
          <div className="mt-2 text-right">
            <p className="text-lg font-semibold italic text-blue-300 glowing-text">
            "Cada página 📚 que lees es un paso hacia el conocimiento ✨. Sumérgete en la aventura de la lectura 📖 y deja que las palabras enciendan tu mente 💡 y tu espíritu 🚀."
            </p>
          </div>
        </div>
          </div>
        </div>
      </div>

      <div>
          <div className="user-info p-2 bg-white shadow-md rounded-lg">
            <p className="text-xl font-bold text-gray-800 mb-2">Institución Educativa: {user.attributes['custom:Colegio']}</p>
            <p className="text-lg text-gray-600">Usuario: {user.attributes['given_name']} {user.attributes['family_name']}</p>
          </div>
          {/* {((user.attributes['custom:categoria_usuario'] === 'DOCENTE' && user.attributes['custom:Nivel'] === 'PRIMARIA' && (["84165"].includes(user.attributes['custom:Colegio']))) || (user.attributes['custom:Grado'] == 4 && (["84165"].includes(user.attributes['custom:Colegio']))) )
            ? <PublicS3Reader grado={4}/>
            : <Cards grado_estudiante={user.attributes['custom:Grado']} categoria_usuario={user.attributes['custom:categoria_usuario']} username={user.username} />} */}
            <Cards grado_estudiante={user.attributes['custom:Grado']} categoria_usuario={user.attributes['custom:categoria_usuario']} username={user.username} />
        </div>
    </div>
  );
}
