import { motion } from "framer-motion";

// Variantes para la animación de las letras
const letterVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1, // Controla el retraso para cada letra
    },
  }),
};

// Variantes para las partículas/pétalos
const petalVariants = {
  hidden: { opacity: 0, y: -100, x: 0 },
  visible: {
    opacity: 1,
    y: [0, 100, 200], // Simula el movimiento hacia abajo
    x: [-50, 0, 50], // Oscilación suave de lado a lado
    transition: {
      duration: 3, // Tiempo de caída
      repeat: Infinity, // Repetir indefinidamente
      ease: "easeInOut",
    },
  },
};

// Letras de "Sumaqtai"
const letters = [..."SumaqtAI"];

const Loader = () => {
  return (
    <div className="relative flex flex-col justify-center items-center h-screen bg-gradient-to-b from-blue-100 to-purple-200">
      {/* Animación del texto de "Sumaqtai" */}
      <motion.div className="flex space-x-1 text-6xl font-bold text-purple-700">
        {letters.map((letter, i) => (
          <motion.span
            key={i}
            custom={i}
            initial="hidden"
            animate="visible"
            variants={letterVariants}
          >
            {letter}
          </motion.span>
        ))}
      </motion.div>

      {/* Partículas simulando pétalos */}
      {[...Array(5)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-4 h-4 bg-pink-300 rounded-full shadow-lg"
          initial="hidden"
          animate="visible"
          variants={petalVariants}
          style={{ top: `${Math.random() * 100}%`, left: `${Math.random() * 100}%` }}
        />
      ))}
    </div>
  );
};

export default Loader;
