
import GetLectoresPuntos from "./lectorespoints/getLectoresPuntos";

const url = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/gettoplectores?operation=read"
const inputdata = {}


function MiPuntaje({user}) {
    return (
        <GetLectoresPuntos url={url} inputdata={inputdata} method={'get'} user={user}>

        </GetLectoresPuntos>
    )
}

export default MiPuntaje;