
import CardHistoryItem from "./Cardhistoryitem";
import GetLectByUserData from "./lectureshistory/getLectureResultsbyUser";

var url = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/getlectureresultsbyuser?id="
const inputdata = {}


function MyHistoryLectures({user}) {
    url = url + user.username
    return (
        <GetLectByUserData url={url} inputdata={inputdata} method={'get'}>

        </GetLectByUserData>
    )
}

export default MyHistoryLectures;