import React, { useState } from 'react';

const QuestionForm = ({onQuestionsChange}) => {
  const [questions, setQuestions] = useState([
    { pregunta: '', alternativas: [{ texto: '', es_correcta: false }, { texto: '', es_correcta: false }] }
  ]);

  const handleQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].pregunta = event.target.value;
    setQuestions(newQuestions);
    onQuestionsChange(newQuestions);
  };

  const handleAlternativeChange = (questionIndex, altIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].alternativas[altIndex].texto = event.target.value;
    setQuestions(newQuestions);
    onQuestionsChange(newQuestions);
  };

  const handleCorrectChange = (questionIndex, altIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].alternativas[altIndex].es_correcta = !newQuestions[questionIndex].alternativas[altIndex].es_correcta;
    setQuestions(newQuestions);
    onQuestionsChange(newQuestions);
  };

  const addAlternative = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].alternativas.push({ texto: '', es_correcta: false });
    setQuestions(newQuestions);
    onQuestionsChange(newQuestions);
  };

  const deleteAlternative = (questionIndex, altIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].alternativas.splice(altIndex, 1);
    setQuestions(newQuestions);
    onQuestionsChange(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { pregunta: '', alternativas: [{ texto: '', es_correcta: false }, { texto: '', es_correcta: false }] }]);
    onQuestionsChange([...questions, { pregunta: '', alternativas: [{ texto: '', es_correcta: false }, { texto: '', es_correcta: false }] }]);
};

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
    onQuestionsChange(newQuestions);
  };


  return (
    //<form onSubmit={handleSubmit}>
        <>

    <div className="separatornh">
                <hr className="separatornh-line" />
                <span classN    ame="separatornh-text">Sección de Preguntas de Comprensión Lectora</span>
                <hr className="separatornh-line" />
    </div>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
        
        <p className="text-lg text-gray-600 mb-6">
            Asegúrese de añadir al menos una pregunta de comprensión lectora, 
            cada pregunta debe tener al menos dos alternativas, y cada pregunta 
            debe tener al menos una alternativa marcada como la respuesta correcta.
        </p>
        </div>
      {questions.map((question, qIndex) => (
        <div key={qIndex} className="question-section">
            <div className="separatornh">
                <hr className="separatornh-line" />
                <span classN    ame="separatornh-text">Pregunta {qIndex + 1}</span>
                <hr className="separatornh-line" />
            </div>
            <input
              className="form-control"
              type="text"
              value={question.pregunta}
              onChange={(e) => handleQuestionChange(qIndex, e)}
              required
            />
            <a className='btn btn-outline-primary rounded-5' onClick={() => deleteQuestion(qIndex)}>
            🗑️ Eliminar Pregunta
          </a>
            <br></br>
          <div className="alternativas">
            {question.alternativas.map((alt, aIndex) => (
              
              <div key={aIndex} className="flex items-center space-x-4 mb-3">
                <input
                    className="form-input flex-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="text"
                    value={alt.texto}
                    onChange={(e) => handleAlternativeChange(qIndex, aIndex, e)}
                    placeholder={`Alternativa ${aIndex + 1}`}
                    required
                />
                <label className="flex items-center space-x-2">
                    <input
                    type="checkbox"
                    checked={alt.es_correcta}
                    onChange={() => handleCorrectChange(qIndex, aIndex)}
                    className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                    <span className="text-gray-700">¿Es la respuesta correcta?</span>
                </label>
                <a
                    href="#"
                    onClick={() => deleteAlternative(qIndex, aIndex)}
                    className="text-red-500 hover:text-red-700 cursor-pointer"
                    aria-label="Eliminar alternativa"
                >
                    🗑️
                </a>
                </div>

            
            
            ))}
            <a className='btn btn-outline-primary rounded-5' onClick={() => addAlternative(qIndex)}>
             ➕ Añadir otra alternativa
            </a>
          </div>
        </div>
      ))}
    
    <div className="separatornh">
                <hr className="separatornh-line" />
                <span classN    ame="separatornh-text">Nueva Pregunta</span>
                <hr className="separatornh-line" />
    </div>
      <a className='btn btn-outline-primary rounded-5'  onClick={addQuestion}>
      ➕ Añadir otra pregunta ❓
      </a>
      <br></br>
      </>
    //</form>
  );
};

export default QuestionForm;
