import React from 'react';

function getColorClass(ratio) {
  if (ratio < 0.2) return 'text-red-500';
  if (ratio < 0.4) return 'text-orange-500';
  if (ratio < 0.6) return 'text-yellow-500';
  if (ratio < 0.8) return 'text-green-500';
  return 'text-green-700'; // Color para un resultado excelente
}

const getCategoryStyles = (cantidad_acertadas, cantidad_preguntas) => {

  if(cantidad_preguntas > 0 ){
    const porcentaje = (cantidad_acertadas / cantidad_preguntas) * 100;
    switch (true) {
      case (porcentaje <= 20):
        return {
          titleBg: 'bg-gradient-to-t from-red-700 to-transparent',
          borderColor: 'border-red-500',
          textColor: 'text-white',
          resultTextColor: 'text-red-500'
        };
      case (porcentaje <= 40):
        return {
          titleBg: 'bg-gradient-to-t from-orange-700 to-transparent',
          borderColor: 'border-orange-500',
          textColor: 'text-white',
          resultTextColor: 'text-orange-500'
        };
      case (porcentaje <= 60):
        return {
          titleBg: 'bg-gradient-to-t from-yellow-900 via-yellow-800 to-transparent',
          borderColor: 'border-yellow-500',
          textColor: 'text-white', 
          resultTextColor: 'text-yellow-500'
        };
      case (porcentaje <= 80):
        return {
          titleBg: 'bg-gradient-to-t from-blue-900 via-blue-800 to-transparent',
          borderColor: 'border-blue-500',
          textColor: 'text-white',
          resultTextColor: 'text-blue-500'
        };
      default:
        return {
          titleBg: 'bg-gradient-to-t from-green-900 via-green-800 to-transparent',
          borderColor: 'border-green-500',
          textColor: 'text-white', // Cambiar a negro para mejor contraste en verde
          resultTextColor: 'text-green-500'
        };
    }
  }

  else
    return {
      titleBg: 'bg-gradient-to-t from-gray-700 to-transparent',
      borderColor: 'border-gray-500',
      textColor: 'text-white'
    };
};

const CardHistoryItem = ({ title, image, aciertos, preguntas, fecha, lecture_id}) => {

  let styles =  getCategoryStyles(aciertos, preguntas)

  return (
    <div
          key={lecture_id}
          onClick={() => window.location.href = `/lecturas/${lecture_id}`}
          className={`relative mb-1 flex-shrink-0 h-40 w-40 overflow-hidden rounded-lg shadow-lg cursor-pointer transition-transform duration-300 hover:scale-105 hover:shadow-xl border-2 ${styles.borderColor}`}
        >
          {/* Imagen */}
          <img
            src={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${lecture_id}.jpg`}
            alt={`${title}`}
            className="w-full transition-transform duration-300"
            onError={(e) => {
              e.target.src = 'https://img-dinoblanco-shs.s3.amazonaws.com/brand/sumaqtaiimagenotfound.jpg'; // Reemplaza con la imagen por defecto
            }}
          />
          {/* Metadata */}
          <div className="absolute bottom-2 right-1 flex justify-between py-3">
            <span className="bg-white bg-opacity-50 italic text-[10px] font-bold text-gray-700 px-0 py-0.5 rounded-full shadow-sm">
              {fecha}
            </span>
          </div>
          <div className="absolute top-0 right-0 w-full flex justify-between p-1">
            <span className="bg-white bg-opacity-75 text-xs text-gray-900 px-2 py-0 rounded-full shadow-sm">
              <div className={`text-4xl font-bold ${styles.resultTextColor}`}>
                {`${aciertos} / ${preguntas}`}
              </div>
            </span>
          </div>
          {/* Título con mejor contraste y sombra */}
          <div
            className={`absolute bottom-0 left-0 w-full ${styles.textColor} text-sm font-medium p-2 text-center transition-opacity duration-300 hover:bg-opacity-75 ${styles.titleBg} shadow-md`}
          >
            {title}
          </div>
        </div>
  )
  return (
    <div key={lecture_id} className="flex-shrink-0 w-60 border border-gray-200 bg-white rounded-xl shadow-md p-3 transition-transform transform hover:scale-105">
            <div className="flex items-center">
              <img
                src={image}
                alt={title}
                className="w-16 h-16 rounded-full border-2 border-blue-500 mr-4"
              />
              <div className="flex flex-col min-w-0">
                <div className="text-gray-900 font-bold text-lg truncate">{title}</div>
                <div className={`text-sm ${getColorClass(aciertos / preguntas)}`}>
                  {`${aciertos} / ${preguntas}`}
                </div>
              </div>
            </div>
            <div className="mt-2 text-sm text-gray-500">{fecha}</div>
            <a
              href={`/lecturas/${lecture_id}`}
              className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-200 no-underline block text-center"
            >
              ¡Leer nuevamente!
            </a>
          </div>
  )

    return (
      <div className="max-w-full w-full flex flex-wrap items-center border border-gray-200 bg-white rounded-xl shadow-md p-3 my-4 transition-transform transform hover:scale-105">
  <div className="flex items-center flex-grow min-w-0">
    <img
      src={image}
      alt={title}
      className="w-16 h-16 rounded-full border-2 border-blue-500 mr-4"
    />
    <div className="flex flex-col justify-between min-w-0">
      <div className="text-gray-900 font-bold text-lg truncate">
        {title}
      </div>
      <div className={`text-sm ${getColorClass(aciertos / preguntas)}`}>
        {`${aciertos} / ${preguntas}`}
      </div>
      <div className="whitespace-nowrap">
      {fecha}
    </div>
    </div>
  </div>
  <div className="flex flex-col items-end ml-auto text-sm text-gray-500">

    <a 
      href={`/lecturas/${lecture_id}`} 
      className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-200 no-underline"
    >
      ¡Leer nuevamente!
    </a>
  </div>
</div>



    );
    return (
        <div className="max-w-full w-full flex items-center border border-gray-200 bg-white rounded-xl shadow-md p-1 my-1 transition-transform transform hover:scale-105">
          <div className="flex items-center">
            <img src={image} alt={title} className="w-16 h-16 rounded-full border-2 border-blue-500 mr-4" />
            <div className="flex flex-col justify-between">
              <div className="text-gray-900 font-bold text-lg">{title}</div>
              <div className="text-gray-900 text-sm">resultado:</div>
              <div className="text-gray-600 text-sm">{`${aciertos} / ${preguntas}`}</div>
            </div>
          </div>
          <div className="ml-auto text-gray-500 text-sm">
            {fecha}
          </div>
        </div>
      );
};

export default CardHistoryItem;
