import React, { useState } from 'react';

function ClasificadorLectura({ onSelectionChange, handlerTipo, handlerTematica, handlerArea, handlerTipoPreguntas}) {

  const [tipo, setTipo] = useState('');
  const [tipoPreguntas, setTipoPreguntas] = useState('');
  const [tematica, setTematica] = useState('');
  const [area, setArea] = useState('');
  var targetTipo = tipo
  var targetTipoPreguntas = tipoPreguntas
  var targetTematica = tematica
  var targetArea = area
  const handleTipoChange = (event) => {
    const selectedTipo = event.target.value;
    targetTipo = selectedTipo
    setTipo(selectedTipo);

    handlerTipo(targetTipo)
  }
  const handleTipoPreguntasChange = (event) => {
    const selectedTipoPreguntas = event.target.value;
    targetTipoPreguntas = selectedTipoPreguntas
    setTipoPreguntas(selectedTipoPreguntas);

    handlerTipoPreguntas(targetTipoPreguntas)
  }
  const handleTematicaChange = (event) => {
    const selectedTematica = event.target.value;
    targetTematica = selectedTematica
    setTematica(selectedTematica);

    handlerTematica(targetTematica)
  }

  const handleAreaChange = (event) => {
    const selectedArea = event.target.value;
    targetArea = selectedArea
    setArea(selectedArea);

    handlerArea(targetArea)
  }


  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
            <label htmlFor="tematicaSelector"  >Temática</label>
            <input id="tematicaSelector" className="form-control" placeholder="#tema,#curso"  value={targetTematica} onChange={handleTematicaChange} >
            </input>
        </div>
        
        <div className="col-md-6">
            <label htmlFor="tipoSelector">Categoría:</label>
            <select id="tipoSelector" className="form-control" value={targetTipo} onChange={handleTipoChange} >
                <option value="#unclasified">Tipo de Lectura</option>
                <option value="instructivo">Instructivo</option>
                <option value="narrativo">Narrativo</option>
                <option value="descriptivo">Descriptivo</option>
                <option value="argumentativo">Argumentativo</option>
                <option value="mixto">Mixto</option>
            </select>
        </div>

        <div className="col-md-6">
            <label htmlFor="areaSelector">Área:</label>
            <select id="areaSelector" className="form-control" value={targetArea} onChange={handleAreaChange} >
                <option value="#unclasified">Asignatura</option>
                <option value="comunicacion">Área de Comunicación</option>
                <option value="matematica">Área de Matemática</option>
                <option value="adpcc">Área de Desarrollo personal, ciudadanía y cívica </option>
                <option value="acc">Área de Ciencias sociales</option>
                <option value="aept">Área de Educación para el trabajo</option>
                <option value="arteycultura">Área de Arte y cultura</option>
                <option value="ingles">Área de Inglés como lengua extranjera</option>
                <option value="cienciatecnologia">Área de Ciencia y tecnología</option>
                <option value="religion">Área de Educación religiosa</option>
                <option value="acomptransversales">Área de Competencias transversales</option>
                <option value="--">--</option>
                <option value="personalsocial">Área de Personal Social </option>
                <option value="cienciayambiente">Área de Ciencia y Ambiente.</option>
                <option value="educacionfisica">Área de Educación Física</option>
                <option value="acastellano">Área de Castellano</option>
            </select>
        </div>

        <div className="col-md-6">
            <label htmlFor="tipoPreguntasSelector">Tipo de Preguntas:</label>
            <select id="tipoPreguntasSelector" className="form-control" value={targetTipoPreguntas} onChange={handleTipoPreguntasChange} >
                <option value="#unclasified">Clasifica las preguntas que tendrá tu lectura</option>
                <option value="infiere_significado">Infiere significado</option>
                <option value="reflexiona_fcc">Reflexiona forma,contenido y contexto</option>
                <option value="recupera_informacion">Recupera información</option>
                <option value="razonamiento_logico">Razona Lógicamente</option>
                <option value="mixto">Mixto</option>
            </select>
        </div>

      </div>
    </div>
  );
}

export default ClasificadorLectura;
