import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

const BotonContador = ({ icono, contador, onClick }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="btn btn-outline-primary  text-white rounded-circle p-2 mb-2" onClick={onClick}>
        {icono}
      </div>
      <span className="text-muted">{contador}</span>
    </div>
  );
};

const Botones = () => {
  const [likes, setLikes] = useState(0);
  const [vistas, setVistas] = useState(0);
  const [respondidas, setRespondidas] = useState(0);
  const [puntaje, setPuntaje] = useState(0);

  const handleLikeClick = () => {
    setLikes(likes + 1);
  };

  const handleVistasClick = () => {
    setVistas(vistas + 1);
  };

  const handleRespondidasClick = () => {
    setRespondidas(respondidas + 1);
  };

  const handlePuntajeClick = () => {
    // Aquí puedes implementar la lógica para agregar un puntaje
    // Por ejemplo, abrir un modal para que el usuario ingrese el puntaje
    // Y luego actualizar el estado de puntaje en consecuencia
  };

  return (
    <div className="container mt-4">
        <div className="d-flex justify-content-around align-items-end">
          <BotonContador icono="🌸" contador={likes} onClick={handleLikeClick} />
          <BotonContador icono="👁️" contador={vistas} onClick={handleVistasClick} />
          <BotonContador icono="📚" contador={respondidas} onClick={handleRespondidasClick} />
          <BotonContador icono="⭐" contador={puntaje} onClick={handlePuntajeClick} />
        </div>
    </div>
  );
};

export default Botones;
