import Card from './Card.tsx'
import Fetchdatos from './Fetchdatos'
import CardHistoryItem from './progreso/Cardhistoryitem.js'
import './cards.css' 
import React, { useState, useEffect , useCallback , memo} from 'react';
import Loader from './LoaderSumaq.js';

const url = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager"
const inputdata = {
    method:'post',
    body: JSON.stringify({
      operation: 'read_v02',
    })
  }

var url_leidas = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/getlectureresultsbyuser?id="
const inputdata_leidas = {}

function formatDateTime(datetimeString) {
    const date = new Date(datetimeString);
    // Options for formatting date and time
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };

    return date.toLocaleDateString('es-ES', options);
}

function formatDateTimeold(datetimeString) {
    const date = new Date(datetimeString);
    // Options for formatting date and time
    const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
        hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
    };
    return date.toLocaleDateString(undefined, options);
}

function calcularPuntajeYLecturas(da_leidas) {
    // Paso 1: Agrupar por lecture_id
    const groupedByLecture = da_leidas.reduce((acc, curr) => {
        const { lecture_id, cantidad_acertadas } = curr;
        if (!acc[lecture_id]) {
            acc[lecture_id] = [];
        }
        acc[lecture_id].push(cantidad_acertadas);
        return acc;
    }, {});

    // Paso 2: Calcular el promedio por lecture_id
    const averageByLecture = Object.values(groupedByLecture).map(aciertos => {
        const sum = aciertos.reduce((a, b) => a + b, 0);
        return sum / aciertos.length;
    });

    // Paso 3: Sumar los promedios para obtener el puntaje total
    const puntajeTotal = averageByLecture.reduce((a, b) => a + b, 0).toFixed(2);

    // Paso 4: Contar la cantidad de lecturas distintas (por lecture_id)
    const cantidadLecturasDistintas = Object.keys(groupedByLecture).length;

    // Retornar ambos valores
    return {
        puntajeTotal,
        cantidadLecturasDistintas
    };
}


function lecturasLeidas(dataleida){
    var da_leidas = []
    var transformedLectures = []
    try {
        da_leidas = JSON.parse(dataleida[0].body).Items
        var _transformedLectures = da_leidas;
        _transformedLectures.sort((a, b) => new Date(b.datetime_exam) - new Date(a.datetime_exam));
        transformedLectures = _transformedLectures
        const resultado = calcularPuntajeYLecturas(da_leidas);
        var puntajeTotal = resultado.puntajeTotal
        var cantidadLecturasDistintas = resultado.cantidadLecturasDistintas
    } catch (error) {
        console.error(error.message);
    }

    return (
        <>
        {
                            
            transformedLectures.map(card => {
                return (
                    <CardHistoryItem
                    title={card.lecture_id.split("-")[0]}
                    image={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.lecture_id}.jpg`}
                    aciertos={card.cantidad_acertadas}
                    preguntas={card.total_preguntas}
                    fecha={formatDateTime(card.datetime_exam)}
                    lecture_id = {card.lecture_id}
                    />
                )})
            }
        </>
    )

    //const dataleida = Fetchdatos(url_leidas,inputdata_leidas,'get')
}
const Row = memo(({ item }) => {
    return (
      <tr key={item.id} className="hover:bg-gray-50">
        <td className="py-2 px-4 border-b">{item.id}</td>
        <td className="py-2 px-4 border-b">{item.grado}</td>
        <td className="py-2 px-4 border-b">{item.nivel}</td>
      </tr>
    );
  });

const Row2 = memo(({item}) => {
    return (
        <div key={item.id} className="mb-4 break-inside-avoid">
          <img
            src={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${item.id}.jpg`}
            alt={`Masonry item ${item.titulo}`}
            className="w-full rounded-lg shadow-lg hover:opacity-80 transition-opacity duration-200"
          />
        </div>
    )
})
const getCategoryStyles = (category) => {
    switch (category) {
      case 'alumno':
        return {
          titleBg: 'bg-gradient-to-t from-purple-700 to-transparent',
          borderColor: 'border-purple-500',
          textColor: 'text-white',
        };
      case 'docente':
        return {
          titleBg: 'bg-gradient-to-t from-orange-700 to-transparent',
          borderColor: 'border-orange-500',
          textColor: 'text-white'
        };
      case 'plataforma':
        return {
          titleBg: 'bg-black bg-opacity-50 ',
          borderColor: 'border-transparent',
          textColor: 'text-white' // Cambiar a negro para mejor contraste en verde
        };
      default:
        return {
          titleBg: 'bg-gradient-to-t from-gray-700 to-transparent',
          borderColor: 'border-gray-500',
          textColor: 'text-white'
        };
    }
  };
  

const Row1 = memo(({item, category}) => {
    const styles = getCategoryStyles(category);
    var _tematica = "" 
    if(category == "alumno"){
        _tematica = `autor: ${item.creator_alias} | Creación Literaria Estudiantil | `

    }

    _tematica = _tematica +item.tematica

    return (
        <div
          key={item.id}
          onClick={() => window.location.href = `/lecturas/${item.id}`}
          className={`relative mb-4 break-inside-avoid overflow-hidden rounded-lg shadow-lg cursor-pointer transition-transform duration-300 hover:scale-105 hover:shadow-xl border-2 ${styles.borderColor}`}
        >
          {/* Imagen */}
          <img
            src={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${item.id}.jpg`}
            alt={`${item.titulo}`}
            className={`w-full transition-transform duration-300`}
            onError={(e) => {
                e.target.src = 'https://img-dinoblanco-shs.s3.amazonaws.com/brand/sumaqtaiimagenotfound.jpg'; // Reemplaza con la imagen por defecto
              }}
          />
          {/* Metadata */}
          <div className={`absolute top-0 left-0 w-full flex justify-between p-2`}>
            <span className={`bg-white bg-opacity-75 text-xs text-gray-800 px-2 py-1 rounded-full shadow-sm `}>
              {_tematica}
            </span>
          </div>
          {/* Título con mejor contraste y sombra */}
          <div
            className={`absolute bottom-0 left-0 w-full ${styles.textColor} text-sm font-medium p-2 text-center transition-opacity duration-300 hover:bg-opacity-75 ${styles.titleBg} shadow-md`}
          >
            {item.titulo}
          </div>
        </div>)
    return (
        <div key={item.id} className="mb-10 break-inside-avoid">
          {/* Contenedor de la imagen con metadata */}
          <div className=" overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <img
              src={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${item.id}.jpg`}
              alt={`Masonry item ${item.titulo}`}
              className="w-full transition-transform duration-300 hover:scale-105"
            />
            {/* Metadata en la esquina inferior derecha */}
            <div className=" bottom-2 right-2 bg-white bg-opacity-80 text-gray-800 text-xs px-2 py-1 rounded-full shadow-md">
              {item.titulo} 
            </div>
            <div className="absolute top-0 left-0 w-full flex justify-between p-2">
                <span className="bg-white bg-opacity-75 text-xs text-gray-800 px-2 py-1 rounded-full">{item.tematica}</span>
                <span className="bg-white bg-opacity-75 text-xs text-gray-800 px-2 py-1 rounded-full">{item.nivel}</span>
          </div>
          </div>
        </div>
    )
})
function Cards({grado_estudiante,categoria_usuario,username}) {
    const [iniciando, setIniciando] = useState(true); // Estado para almacenar los elementos
    const [dataleida, setDataleida] = useState(null); // Estado para almacenar los elementos
    const [data_lecturas_leidas, setData_lecturas_leidas] = useState([]); // Estado para almacenar los elementos

    const [data, setData] = useState([]);

    const [visibleItems, setVisibleItems] = useState(20);
    const loadMoreItems = () => {
        setVisibleItems((prev) => prev + 20); // Añadir 20 elementos más cada vez que se scrollea
    };

    useEffect(() =>  {
        async function sacaDatos(iniciando){
            if(iniciando){
                try {
                    const response = await fetch(url_leidas+username);
              
                    //const data = await response.json();
                    var datar = await response.json()
                    var data_lecturas_leidas_old = JSON.parse(datar[0].body).Items
                    console.log("................")
                    setDataleida(datar)
                    setData_lecturas_leidas(data_lecturas_leidas_old)

                    setIniciando(false);
          
                } catch (error) {
                    console.error('Error al obtener registros de lecturas leidas:', error);
                } finally {
                        setIniciando(false);
                }
            }
        }
        sacaDatos(iniciando);
    }, []);

    useEffect(() => {
        var url = ''
        if(grado_estudiante<=6)
            url = `https://img-dinoblanco-shs.s3.amazonaws.com/c/p/${grado_estudiante}/lecturas.json`
        else
            url = `https://img-dinoblanco-shs.s3.amazonaws.com/c/s/${parseInt(grado_estudiante,10)-6}/lecturas.json`
    
        const fetchData = async () => {
          try {
            const response = await fetch(url); // Reemplaza con tu URL
            const jsonData = await response.json();
            setData(jsonData);
          } catch (error) {
            console.error('Error fetching public JSON:', error);
          }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
          // Detectar si el usuario ha llegado al final de la página (90% del scroll)
          if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight * 0.9) {
            loadMoreItems();
            /* if(dataleida != null){
                if(data.length >= visibleItems)
                    loadMoreItems();
            }else{
                console.log(visibleItems,data.length)
                console.log("YA NO HAY MÁS")
            } */
            
          }
    };

    window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    let resultado = {}
    var puntajeTotal = 0
    var cantidadLecturasDistintas = 0
    let transformedLectures = []
    if(dataleida != null){
        if( data.slice(0, visibleItems).filter(lectura => !data_lecturas_leidas.some(leida => leida.lecture_id === lectura.id)).length < 10  ){
        //if( data.filter(lectura => lectura.tematica.includes('ugelsihuas')).length < 10){
            console.log(visibleItems)
            console.log(data)
            console.log("++++++++++++++++++++++++++++++++++++")
            //console.log(data.filter(lectura => lectura.tematica.includes('ugelsihuas')))
            //fetchItems();
            if(data.length > visibleItems)
            loadMoreItems();
            console.log("{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}")
        }

        resultado = calcularPuntajeYLecturas(data_lecturas_leidas);
        puntajeTotal = resultado.puntajeTotal
        cantidadLecturasDistintas = resultado.cantidadLecturasDistintas
        var _transformedLectures = data_lecturas_leidas;
        _transformedLectures.sort((a, b) => new Date(b.datetime_exam) - new Date(a.datetime_exam));
        transformedLectures = _transformedLectures
    }
    console.log(".#.#:#.#.#.#:#.#.#.#.3")

    return (
        dataleida != null?
                <div className="w-full py-0 px-3 ">

                    <div className="sticky top-0 z-1 bg-gradient-to-r from-green-400 to-blue-500 text-white pb-0.5 pt-4 px-4 shadow-lg">
                        <div className="max-w-7xl mx-auto flex justify-between items-center">
                            <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v4h1l.293-.293a1 1 0 011.414 0l2.586 2.586 1.293-1.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414l1.293-1.293-2.586-2.586a1 1 0 010-1.414L9 8V7H5V3H3z" />
                            </svg>
                            <div className="text-xl font-bold">
                                Mi Puntaje : <span className="text-3xl">{puntajeTotal}</span>
                            </div>
                            </div>
                            <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l-7 7 7 7" />
                            </svg>
                            <div className="text-xl font-bold">
                                Mis Lecturas Leidas: <span className="text-3xl">{cantidadLecturasDistintas}</span>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 mb-6">
                        {/* Mensaje llamativo */}
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-1 rounded-md shadow-lg ">
                            <h2 className="text-2xl font-bold">¡Refuerza tus lecturas!</h2>
                            <p className="text-[12px] m-0">Repasa tus lecturas ya leídas y sigue aprendiendo.</p>
                        </div>
                        <div className="flex space-x-4 overflow-x-auto scrollbar-hide py-2">
                            {


                                transformedLectures.map(card => {
                                return (
                                    <CardHistoryItem
                                    title={card.lecture_id.split("-")[0]}
                                    image={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.lecture_id}.jpg`}
                                    aciertos={card.cantidad_acertadas}
                                    preguntas={card.total_preguntas}
                                    fecha={formatDateTime(card.datetime_exam)}
                                    lecture_id = {card.lecture_id}
                                    />
                                )})
                            }
                        </div>
                    </div>

                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-0 rounded-md shadow-lg ">
                        <h2 className="text-2xl font-bold">¡Explora Leyendo!</h2>
                    </div>
                    <div className="columns-2 sm:columns-2 md:columns-3 lg:columns-4 xl:columns-5 gap-4 p-0">
                            {
                            /* data.filter(lectura => 
                                !data_lecturas_leidas.some(leida => leida.lecture_id === lectura.id)
                            ) */data.slice(0, visibleItems).
                            filter(lectura => 
                                !data_lecturas_leidas.some(leida => leida.lecture_id === lectura.id)
                            ).map((item, index) => {
                                if(item.active  != false){

                                    if(item.creator_alias == null || item.creator_alias.includes("@sumaqt.")){

                                        if(parseInt(item.grado,10) >=20)
                                            return <Row1 key={item.id} item={item} category={'docente'} />
                                        else
                                            return <Row1 key={item.id} item={item} category={'plataforma'} />

                                    }else{
                                        return <Row1 key={item.id} item={item} category={'alumno'} />
                                    }
                                        
                                }
                                else{
                                    console.log(item)
                                    console.log(".a.a.a.a.a.a")
                                    return <></>
                                }
                            })}
                    </div>

                </div>
                
        :
        <Loader></Loader>
    );
    
}
function Cards2({grado_estudiante,categoria_usuario,username}) {
    const [iniciando, setIniciando] = useState(true); // Estado para almacenar los elementos
    const [dataleida, setDataleida] = useState(null); // Estado para almacenar los elementos
    const [data_lecturas_leidas, setData_lecturas_leidas] = useState([]); // Estado para almacenar los elementos

    const [items, setItems] = useState([]); // Estado para almacenar los elementos
    const [loading, setLoading] = useState(false); // Estado de carga
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null); // Estado para la paginación
    const [gradoInput, setGradoInput] = useState(5); // Ejemplo de gradoInput, ajusta según sea necesario


    const [data, setData] = useState([]);
    //const [loading, setLoading] = useState(true);

    //const dataleida = Fetchdatos(url_leidas + username,inputdata_leidas,'get')
    //var data_lecturas_leidas = dataleida ? JSON.parse(dataleida[0].body).Items : []

    //var dataleida = null
    //var data_lecturas_leidas = []
    useEffect(() =>  {
        async function sacaDatos(iniciando){
            if(iniciando){
                try {
                    const response = await fetch(url_leidas+username);
              
                    //const data = await response.json();
                    var datar = await response.json()
                    var data_lecturas_leidas_old = JSON.parse(datar[0].body).Items
                    console.log("................")
                    setDataleida(datar)
                    setData_lecturas_leidas(data_lecturas_leidas_old)

                    setIniciando(false);
          
                } catch (error) {
                    console.error('Error al obtener registros de lecturas leidas:', error);
                } finally {
                        setIniciando(false);
                }
            }
        }

        sacaDatos(iniciando);
      }, []);
    
    
    let isMounted = false;
    const fetchItems =  useCallback(async () => {
        if (!(loading || lastEvaluatedKey === 'end')){

            setLoading(true);
            isMounted = true; // Bandera para verificar si el componente está montado

            try {
              const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operation: 'read_v02',
                    LastEvaluatedKey: lastEvaluatedKey,
                    grado: grado_estudiante,
                    category: categoria_usuario
                    }),
              });
        
              //const data = await response.json();
              const datav = await response.json()
              const dataq = JSON.parse(datav[0].body).Items
              
              if (isMounted && dataq && dataq.length > 0) {
                setData(
                    (prevItems) => {
                        const newItems = dataq.filter(
                          (newItem) => !prevItems.some((prevItem) => prevItem.id === newItem.id)
                        ); // Filtra para evitar elementos duplicados
                        return [...prevItems, ...newItems];
                      }
                ); // Agregar nuevos elementos a los existentes
                setLastEvaluatedKey(JSON.parse(datav[0].body).LastEvaluatedKey|| 'end'); // Actualizar la clave para la paginación
                console.log("new items ************************+")
                if(dataq != null){
                    isMounted = false
                    setLoading(false);
                }
               }else {
                setLastEvaluatedKey('end'); // No hay más datos para cargar
              }
    
              console.log("---------------------------")
            } catch (error) {
              console.error('Error al obtener registros:', error);
            } finally {
               //setLoading(false);
            }

        } // Si ya está cargando, no hacer nada

    },[loading, lastEvaluatedKey,data]);
    
    // Efecto para detectar el desplazamiento
    useEffect(() => {
        const handleScroll = () => {
            const threshold = document.documentElement.offsetHeight * 0.9; // Ajusta a un 60% de la altura total
            if (
              window.innerHeight + document.documentElement.scrollTop >= threshold
            ) {
                if(!isMounted)
                    fetchItems();
            }
          };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading,lastEvaluatedKey]); // Ejecutar efecto cuando cambie lastEvaluatedKey

    useEffect(() => {
        fetchItems(); // Cargar elementos al montar el componente
    }, []);

    let resultado = {}
    var puntajeTotal = 0
    var cantidadLecturasDistintas = 0
    let transformedLectures = []

    if(dataleida != null){
        if(!isMounted)
        if( data.filter(lectura => !data_lecturas_leidas.some(leida => leida.lecture_id === lectura.id)).length < 10){
        //if( data.filter(lectura => lectura.tematica.includes('ugelsihuas')).length < 10){

            console.log(data)
            console.log("++++++++++++++++++++++++++++++++++++")
            console.log(data.filter(lectura => lectura.tematica.includes('ugelsihuas')))
            fetchItems();
            console.log("{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}")
        }

        resultado = calcularPuntajeYLecturas(data_lecturas_leidas);
        puntajeTotal = resultado.puntajeTotal
        cantidadLecturasDistintas = resultado.cantidadLecturasDistintas
        var _transformedLectures = data_lecturas_leidas;
        _transformedLectures.sort((a, b) => new Date(b.datetime_exam) - new Date(a.datetime_exam));
        transformedLectures = _transformedLectures
    }

    return (
        dataleida != null?
                <div className="w-full py-0 px-3 ">

                    <div className="sticky top-0 z-1 bg-gradient-to-r from-green-400 to-blue-500 text-white pb-0.5 pt-4 px-4 shadow-lg">
                        <div className="max-w-7xl mx-auto flex justify-between items-center">
                            <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v4h1l.293-.293a1 1 0 011.414 0l2.586 2.586 1.293-1.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414l1.293-1.293-2.586-2.586a1 1 0 010-1.414L9 8V7H5V3H3z" />
                            </svg>
                            <div className="text-xl font-bold">
                                Mi Puntaje : <span className="text-3xl">{puntajeTotal}</span>
                            </div>
                            </div>
                            <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l-7 7 7 7" />
                            </svg>
                            <div className="text-xl font-bold">
                                Mis Lecturas Leidas: <span className="text-3xl">{cantidadLecturasDistintas}</span>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 mb-6">
                        {/* Mensaje llamativo */}
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-1 rounded-md shadow-lg ">
                            <h2 className="text-2xl font-bold">¡Refuerza tus lecturas!</h2>
                            <p className="text-[12px] m-0">Repasa tus lecturas ya leídas y sigue aprendiendo.</p>
                        </div>
                        <div className="flex space-x-4 overflow-x-auto scrollbar-hide py-2">
                            {


                                transformedLectures.map(card => {
                                return (
                                    <CardHistoryItem
                                    title={card.lecture_id.split("-")[0]}
                                    image={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.lecture_id}.jpg`}
                                    aciertos={card.cantidad_acertadas}
                                    preguntas={card.total_preguntas}
                                    fecha={formatDateTime(card.datetime_exam)}
                                    lecture_id = {card.lecture_id}
                                    />
                                )})
                            }
                        </div>
                    </div>


                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-0 rounded-md shadow-lg ">
                        <h2 className="text-2xl font-bold">¡Explora Leyendo!</h2>
                    </div>
                    <div className="columns-2 sm:columns-2 md:columns-3 lg:columns-4 xl:columns-5 gap-4 p-0">
                            {
                            data.filter(lectura => 
                                !data_lecturas_leidas.some(leida => leida.lecture_id === lectura.id)
                            ).map((item, index) => {
                                if(item.active  != false){

                                    if(item.creator_alias == null || item.creator_alias.includes("@sumaqt.")){

                                        if(parseInt(item.grado,10) >=20)
                                            return <Row1 key={item.id} item={item} category={'docente'} />
                                        else
                                            return <Row1 key={item.id} item={item} category={'plataforma'} />

                                    }else{
                                        return <Row1 key={item.id} item={item} category={'alumno'} />
                                    }
                                        
                                }
                                else{
                                    console.log(item)
                                    console.log(".a.a.a.a.a.a")
                                    return <></>
                                }
                            })}
                    </div>

                </div>
                
        :
        <Loader></Loader>
    );
    
}

function Cards1({grado_estudiante,categoria_usuario,username}) {
    const data =  Fetchdatos(url,inputdata,'post')

    url_leidas = url_leidas + username

    const dataleida = Fetchdatos(url_leidas,inputdata_leidas,'get')
    console.log(dataleida)
    var da_leidas = []
    var transformedLectures = []
    try {
        da_leidas = JSON.parse(dataleida[0].body).Items
        var _transformedLectures = da_leidas;
        _transformedLectures.sort((a, b) => new Date(b.datetime_exam) - new Date(a.datetime_exam));
        transformedLectures = _transformedLectures
        console.log(username)
        console.log("jajajajaja")
        console.log(da_leidas)
        const resultado = calcularPuntajeYLecturas(da_leidas);
        var puntajeTotal = resultado.puntajeTotal
        var cantidadLecturasDistintas = resultado.cantidadLecturasDistintas
        console.log(resultado)
        
    } catch (error) {
        console.error(error.message);
    }

    var da = []

     function filter_lectures(gradodellalectura){
        var grado_estudiante_param = grado_estudiante
        if(grado_estudiante === undefined ){
            grado_estudiante_param = gradodellalectura['grado']
            //grado_estudiante_param = 3
        }

      
            
        if(gradodellalectura['active'] == false){
            console.log(gradodellalectura)
            return false
        }
        
        if(parseInt(gradodellalectura['grado'],10) >= 20){
            if (categoria_usuario.toLowerCase() == "docente") 
                return true
                //return (parseInt(gradodellalectura['grado'],10)-20 >= parseInt(grado_estudiante_param,10)-2) && (parseInt(gradodellalectura['grado'],10)-20 <= parseInt(grado_estudiante_param,10)+2)
            else 
                return false

        }else{
            if (categoria_usuario.toLowerCase() == "docente") 
                return (parseInt(gradodellalectura['grado'],10) >= parseInt(grado_estudiante_param,10)-3) && (parseInt(gradodellalectura['grado'],10) <= parseInt(grado_estudiante_param,10)+3)
            else 
                return (parseInt(gradodellalectura['grado'],10) >= parseInt(grado_estudiante_param,10)-2) && (parseInt(gradodellalectura['grado'],10) <= parseInt(grado_estudiante_param,10)+2)
        }
        
        
    } 

    function get_nivel_lectura( grado_estudiante_param, gradodellalectura){

        if(grado_estudiante_param === undefined )
            grado_estudiante_param = gradodellalectura

        var factordenivel = 0.6
        var nivellecturavisible = Math.round(Math.max(
                Math.min(
                    Math.exp((parseInt(gradodellalectura,10) - parseInt(grado_estudiante_param,10))*factordenivel),3
                ),1
            ))
        return nivellecturavisible
        
    }


    if (data != null ){
        console.log(data)
        da = JSON.parse(data[0].body).Items
        console.log("cantidad retornado")
        console.log(da)
        console.log(JSON.parse(data[0].body).LastEvaluatedKey) //caperucitarojayblancaaiversionperuana-97be994db84d4fd2a9ff9052bd694326
        console.log(JSON.parse(data[0].body))
        console.log("jejejeje")
        da = da.filter(filter_lectures)
    }


    if (!Array.isArray(data)){
        return(data)
    }
    else{
        return (
            <>

                <div className="sticky top-0 z-1 bg-gradient-to-r from-green-400 to-blue-500 text-white p-4 shadow-lg">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v4h1l.293-.293a1 1 0 011.414 0l2.586 2.586 1.293-1.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414l1.293-1.293-2.586-2.586a1 1 0 010-1.414L9 8V7H5V3H3z" />
                    </svg>
                    <div className="text-xl font-bold">
                        Mi Puntaje : <span className="text-3xl">{puntajeTotal}</span>
                    </div>
                    </div>
                    <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l-7 7 7 7" />
                    </svg>
                    <div className="text-xl font-bold">
                        Mis Lecturas Leidas: <span className="text-3xl">{cantidadLecturasDistintas}</span>
                    </div>
                    </div>
                </div>
                </div>

                <div className="flex flex-col md:flex-row w-full min-h-screen">
                    <div className="w-full md:w-3/4 p-4 ">
                    <h2 className="text-2xl font-bold mb-4">Lecturas Disponibles</h2>
                    <div className="space-y-4">

                            <div className="container mt-4 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 d-flex align-items-stretch">     
                                <div className="card-deck row mt-4" id="product-container">
                                {   
                                            
                                    da.filter(lectura => 
                                        !da_leidas.some(leida => leida.lecture_id === lectura.id)
                                    ).map(card => {
                                        try{
                                            var nivelactualizado = get_nivel_lectura(grado_estudiante, card.grado)
                                            var flarnaranjacard = ""
                                            if(parseInt(card.grado,10) >= 20){
                                                flarnaranjacard = "bg-warning"
                                            }
                                                return (
                                                    <div id={`#${card.id}`} className="col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
                                                            <Card  title={card.titulo} imageSource={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.id}.jpg`} content={''} id={card.id} current_card={card} dificultadactualizada={nivelactualizado} gradoestudiante={grado_estudiante} flg = {flarnaranjacard}/>
                                                    </div>
                                                )
                                            /* }else{
                                                return (
                                                    <div  id={`#${card.id}`} className="col-lg-3 col-md-4 col-sm-4 col-12 mb-4 d-flex align-items-stretch">
                                                            <Card  title={card.titulo} imageSource={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.id}.jpg`} content={''} id={card.id} current_card={card} dificultadactualizada={nivelactualizado} gradoestudiante={grado_estudiante}/>
                                                    </div>
                                                )
                                            } */
                                                
                                        } 
                                        catch{
                                            console.log(JSON.stringify(card))
                                            return <></>
                                        }
                                            
                                            
                                    })
                                }
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="w-full md:w-1/4 p-4 ">
                                <h2 className="text-2xl font-bold mb-4">Lecturas Leídas</h2>
                            <div className="space-y-4 max-h-screen overflow-y-auto">
                            {
                            
                            transformedLectures.map(card => {
                                return (
                                    <CardHistoryItem
                                    title={card.lecture_id.split("-")[0]}
                                    image={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.lecture_id}.jpg`}
                                    aciertos={card.cantidad_acertadas}
                                    preguntas={card.total_preguntas}
                                    fecha={formatDateTime(card.datetime_exam)}
                                    lecture_id = {card.lecture_id}
                                    />
                                )})
                            }
                                    </div>
                                </div>
                </div>
            </>
        )
    }
}

export default Cards