import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { useEffect } from "react";
import { useParams } from "react-router-dom";
//import Cards from "../components/Cards"


function CardPunctuacion({ emoji, nota, mensaje }) {
  const cardVariants = {
    offscreen: { opacity: 0, scale: 0.9 },
    onscreen: {
      opacity: 1,
      scale: 1,
      rotate: 360 * nota,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.5
      }
    }
  };

  return (
    <div className='resultados-container'>
      <motion.div
        className="card-punctuacion"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={cardVariants}
      >
        <div className="emoji-container">
          {emoji}
        </div>
        <div className="nota-container">
          <span className="nota">{nota}</span>
        </div>
      </motion.div>
      <div className='resultados-message'>
        <p>{mensaje}</p>
      </div>
    </div>
  );
}

export default function Lecturaresult({ user, signOut }) {
  const params = useParams();
  const notacalificada = parseInt(params.points, 10);
  const nombrealumno = user.attributes['name'] || 'Estudiante';

  let mensaje;
  if (notacalificada === 0)
    mensaje = `¡Vamos por más, ${nombrealumno}! ¡Sigue practicando!`;
  else if (notacalificada === 1)
    mensaje = `¡Genial, ${nombrealumno}! Ya estás en el camino correcto.`;
  else if (notacalificada === 2)
    mensaje = `¡Muy bien, ${nombrealumno}! Estás leyendo mejor.`;
  else if (notacalificada === 3)
    mensaje = `¡Excelente, ${nombrealumno}! Estás haciendo un buen trabajo.`;
  else if (notacalificada === 4)
    mensaje = `¡Fantástico, ${nombrealumno}! Eres un lector habilidoso.`;
  else
    mensaje = `¡Increíble, ${nombrealumno}! Eres un lector excepcional.`;

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);
  useEffect(() => {
    const animation = animate(count, notacalificada, { duration: 1 });
    return animation.stop;
  }, [notacalificada]);

  return (
    <div className="resultados-wrapper">
      <CardPunctuacion emoji={<div className="emoji">🎉</div>} nota={notacalificada} mensaje={mensaje} />
      
    </div>
  );
}