import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.min.css'


import { motion } from 'framer-motion';
import logoatlas from './assets/sumaq_atlas.jpg'
import HeroMessage from './components/imgBackground/HeroMessage';
import GridBackground from './components/imgBackground/gridBackground';
import ImageBackground from './components/imgBackground/ImageBackground';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <App />

    </>
    
);
