import { useNavigate } from "react-router-dom"
import { useState } from "react";
import Postlectura from "../components/Postlectura"
import uuid from 'react-uuid'
import LevelSelector from './newLect/LevelSelector'
import ClasificadorLectura from "./newLect/ClasificadorLectura";
import QuestionForm from "./newLect/PreguntasForm";

function limpiarString(texto) {
    // Utilizar normalize para descomponer caracteres acentuados en caracteres base y marcas
    const textoDescompuesto = texto.normalize("NFD");
  
    // Utilizar una expresión regular para eliminar marcas diacríticas y caracteres no alfabéticos
    const textoLimpio = textoDescompuesto.replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s]/gi, '');
  
    // Remover espacios y convertir a minúsculas
    const textoFinal = textoLimpio.replace(/\s/g, '').toLowerCase();
  
    return textoFinal;
}

function createUserAlias(user) {

    const givenName = user.attributes['given_name'] || ''; // Asegúrate de que given_name esté definido
    const familyName = user.attributes['family_name'] || '';
    
    const givenNameParts = givenName.split(' ');
    const familyNameParts = familyName.split(' ');

    // Toma la primera palabra del `given_name`
    const firstName = givenNameParts[0];

    // Obtén las iniciales de las siguientes partes de `given_name`
    const givenNameInitials = givenNameParts.slice(1).map(name => name.charAt(0)).join('');

    // Obtén las iniciales de `family_name`
    const familyNameInitials = familyNameParts.map(name => name.charAt(0)).join('');

    // Combina todo en el formato deseado
    const userAlias = `@${firstName}_${givenNameInitials}.${familyNameInitials}`;

    return userAlias.toLowerCase(); // Convertir a minúsculas para mayor consistencia
}

function getCreatorLevel(user) {
    const categoriaUsuario = user.attributes['custom:categoria_usuario'];
    const grade = parseInt(user.attributes['custom:grade'], 10);

    let level = '';

    if (grade <= 2) {
        level = 'ini';
    } else if (grade < 9) {
        level = 'pri';
    } else if (grade < 14) {
        level = 'sec';
    } else if (grade <= 22) {
        level = 'INI';
    } else if (grade <= 28) {
        level = 'PRI';
    } else if (grade < 34) {
        level = 'SEC';
    } else {
        level = 'DOC';
    }

    // Combina la categoría de usuario con el nivel determinado
    const creatorLevel = `${categoriaUsuario}_${level}`;

    return creatorLevel;
}



function publicaLectura( history, titulo, contenido, nivelSeleccionado, gradoSeleccionado, rangoSeleccionado, user, tipoSeleccionado,tipoPreguntasSeleccionado,tematicaSeleccionado, areaSeleccionado, questions ){

    //event.preventDefault();
    var username = user.username
    var aliasname = createUserAlias(user)
    var creator_level = getCreatorLevel(user)
    contenido = contenido.replace(/\n/g, '\\n').replace(/"/g, "'");
    titulo = titulo.replace(/\n/g, '\\n').replace(/"/g, "'");
    var preguntas = questions
    console.log(questions)

    var lect = `{
        "titulo": "${titulo}",
        "level_of_school": "${nivelSeleccionado}",
        "level_of_grade": "${gradoSeleccionado}",
        "difficulty": "3",
        "courses_hashtag": "${tematicaSeleccionado}",
        "lectura": "",
        "preguntas":[]
    }`

    var ponderado = 0
    if(rangoSeleccionado === "docentes"){
        ponderado +=20
    }
    if(nivelSeleccionado === "secundaria"){
        ponderado +=6
    }
    ponderado +=parseInt(gradoSeleccionado)
    var rangoEstudiantes = rangoSeleccionado + " " +nivelSeleccionado


    var lectureuniversalidentificator = limpiarString(titulo)+"-"+limpiarString(uuid())

    var lectureItem = {
        "id": lectureuniversalidentificator, 
        "texto": contenido, 
        "titulo": titulo, 
        test: lect, 
        grado: ponderado, 
        nivel: rangoEstudiantes,
        creator: username, 
        creator_alias: aliasname,
        creator_level:creator_level,
        tematica: tematicaSeleccionado, 
        tipo_lectura: tipoSeleccionado, 
        tipo_preguntas: tipoPreguntasSeleccionado,
        area: areaSeleccionado,
        datetime_upload:new Date()
    }



    //const valid = questions.every(q => q.pregunta && q.alternativas.length >= 2 && q.alternativas.every(a => a.text));
    /* const valid = questions.every(q => 
        q.pregunta &&
        q.alternativas.length >= 2 &&
        q.alternativas.every(a => a.texto) &&
        q.alternativas.some(a => a.es_correcta)
        ); */
    console.log(questions.length)
    //console.log(valid)
    
    if ( questions.length) {
        console.log('Form Data:', JSON.stringify({ preguntas }));
        var lecturaactiva = username.includes('sumaqt') ? true:false
        
        
        //lectureItem['preguntas'] = questions
        lect = `{
            "titulo": "${titulo}",
            "level_of_school": "${nivelSeleccionado}",
            "level_of_grade": "${gradoSeleccionado}",
            "difficulty": "2",
            "courses_hashtag": "${tematicaSeleccionado}",
            "lectura": "",
            "preguntas":${questions}
        }`

        console.log("test:",lect)

        const currentDate = new Date();
        const datetime_texto = currentDate;
        // Obtener año y mes en formato YYYY_MM
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso se suma 1
        const MES_CARGA = `${year}_${month}`;
        
        lectureItem = {
            "id": lectureuniversalidentificator, 
            "texto": contenido, 
            "titulo": titulo, 
            test: lect, 
            grado: ponderado, 
            nivel: rangoEstudiantes,
            creator: username, 
            creator_alias: aliasname,
            creator_level:creator_level,
            tematica: tematicaSeleccionado, 
            tipo_lectura: tipoSeleccionado, 
            tipo_preguntas: tipoPreguntasSeleccionado,
            area: areaSeleccionado,
            datetime_upload:datetime_texto,
            "active":lecturaactiva,
            "mes_carga":MES_CARGA
        }
        console.log(lectureItem)
        //PostPublicLectura(lectureItem)
        // Aquí puedes hacer la lógica para postear los datos
        /* setTimeout(() => {
            history(`/lecturas/${lectureuniversalidentificator}`);
          }, 2000);  */
        return {
            faltan_datos:false,
            lecture_items: lectureItem
        }
    } else {
        
        alert('Por favor, llena todos los campos de las preguntas y al menos dos alternativas por pregunta y al menos una marcada como respuesta correcta.');
        return  {
            faltan_datos:true,
            lecture_items: []
        }
    }

     //PostPublicLectura(lectureItem)

    
    /* setTimeout(() => {
        history(`/lecturas/${lectureuniversalidentificator}`);
      }, 2000); */
     

}

export default function PublishLectureAdminX({user}) {
    const history = useNavigate ();
    const funcionprueba = publicaLectura;

    const [titulo, setTitulo] = useState('');
    const [contenido, setContenido] = useState('');
    const [questions, setQuestions] = useState([]);

    /* const handleQuestionsChange = (newQuestions) => {
        setQuestions(newQuestions);
      }; */
    const handleQuestionsChange = (event) => {
        setQuestions(event.target.value);
    };

    const handleTituloChange = (event) => {
        setTitulo(event.target.value);
    };

    const [nivelSeleccionado, setNivelSeleccionado] = useState('inicial');
    const [gradoSeleccionado, setGradoSeleccionado] = useState(2);
    const [rangoSeleccionado, setRangoSeleccionado] = useState('estudiantes');
    const [tipoSeleccionado, setTipoSeleccionado] = useState('#unclasified');
    const [tipoPreguntasSeleccionado, setTipoPreguntasSeleccionado] = useState('#unclasified');
    const [tematicaSeleccionado, setTematicaSeleccionado] = useState('#unclasified');
    const [areaSeleccionado, setAreaSeleccionado] = useState('#unclasified');

    const handleSelectionChange = (nivel, grado, rango) => {
        
        setNivelSeleccionado(nivel);
        setGradoSeleccionado(grado);
        setRangoSeleccionado(rango);
    
    };
    const handleGenericChange = (setNuevoValor, nuevoValor) => {
        setNuevoValor(nuevoValor);
    };
    
    

    const handleContenidoChange = (event) => {
        setContenido(event.target.value);
    };

    const [valorTextarea, setValorTextarea] = useState('');

    const handleChange = (event) => {
      setValorTextarea(event.target.value);
    };

    const [isPublishing, setIsPublishing] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault(); // Previene el comportamiento predeterminado del formulario
        setIsPublishing(true); // Indica que la publicación ha comenzado

        try {
            // Publica la lectura
            var faltan_datos = funcionprueba(history, titulo, contenido, nivelSeleccionado, gradoSeleccionado, rangoSeleccionado,  user, tipoSeleccionado,tipoPreguntasSeleccionado,tematicaSeleccionado,areaSeleccionado, questions )

            if(faltan_datos.faltan_datos){
                setIsPublishing(false); 
            }else{
                setIsPublishing(true); 
                await PostPublicLectura(faltan_datos.lecture_items);
                history(`/lecturas/${faltan_datos.lecture_items.id}`);
            }
            // Redirige al usuario después de la publicación
            
        } catch (error) {
            // Maneja cualquier error aquí
            console.error("Error al publicar la lectura:", error);
        } finally {
            setIsPublishing(false); // Restablece el estado de la publicación
        }
    };

    return <>

<div className="text-center py-10">
  <h2 className="text-5xl font-bold text-blue-800 mb-2">
    <span className="block bg-gradient-to-r from-green-400 to-blue-500 text-transparent bg-clip-text">
      Lecturas que Florecen
    </span>
  </h2>
  <p className="text-xl text-gray-700 mx-auto max-w-2xl">
    Publica tus lecturas y crea preguntas para evaluar la comprensión de manera efectiva y atractiva.
  </p>
</div>

        <div className="container mt-1">

            <form>
                <br></br>
                <br></br>
                
                <input class="form-control form-control-lg" type="text" placeholder="Título de la lectura"  id="titulo" value={titulo} onChange={handleTituloChange} ></input>
                <input className="form-control" type="text" value={'Creador: '+user.attributes['family_name']+' '+user.attributes['given_name']+' '+user.attributes['nickname']} aria-label={user.username} disabled readonly />
                <ClasificadorLectura onSelectionChange={handleGenericChange} handlerTipo={setTipoSeleccionado} handlerTematica={setTematicaSeleccionado} handlerArea={setAreaSeleccionado} handlerTipoPreguntas={setTipoPreguntasSeleccionado}/>
                <LevelSelector onSelectionChange={handleSelectionChange} />
                

                <br>
                </br>
                

                <div className="form-group">
                    <textarea className="form-control"  placeholder="Ingrese la lectura a compartir ..."  rows="3" id="contenido" value={contenido} onChange={handleContenidoChange}></textarea>
                </div>

                <br>
                </br>

                <div className="form-group">
                    <textarea className="form-control"  placeholder="Ingrese las preguntas en formato json establecido..."  rows="3" id="preguntasformato" value={questions} onChange={handleQuestionsChange}></textarea>
                </div>

                <br></br>
                {/* <textarea value={valorTextarea} onChange={handleChange} />
                    <p>Valor del textarea: {valorTextarea}</p> */}
                    {/* <a onClick={() => funcionprueba(history, titulo, contenido, nivelSeleccionado, gradoSeleccionado, rangoSeleccionado,  user, tipoSeleccionado,tipoPreguntasSeleccionado,tematicaSeleccionado,areaSeleccionado, questions )} className='btn btn-outline-primary rounded-5'>
                     */}
                <a  onClick={!isPublishing ? handleSubmit : null} 
                    className={`btn btn-outline-primary rounded-5 ${isPublishing ? 'opacity-50 cursor-not-allowed' : ''}`}
                    style={{ pointerEvents: isPublishing ? 'none' : 'auto' }}
                >
                    {isPublishing ? 'Publicando...' : 'Publicar'}
                </a>

                {isPublishing && <p className="text-blue-500">Publicando la lectura, por favor espera...</p>}

            </form>
        
        </div>
       
    </> 
    
}

async function PostPublicLectura(lectureItem) {
    
    async function fetchData() {
      try {
        
        const response = await fetch(
            "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/",

            {
                method:'post',
                body: JSON.stringify(
                  {
                      operation: "create", 
                      payload: {"Item": lectureItem}
                  }
                )
              }
        );
        if (response.ok) {
          const lista = await response.json();

        } else {
            console.log("Hubo un error al obtener la lista de lecturas.");
        }
      } catch (error1) {
        console.log("No pudimos hacer la solicitud para obtener las lecturas");
      }
    }
    await fetchData();
  
}