import { useEffect, useState } from "react";


import CardHistoryItem from "../Cardhistoryitem";


function formatDateTime(datetimeString) {
  const date = new Date(datetimeString);
  
  // Options for formatting date and time
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
  };
  
  return date.toLocaleDateString(undefined, options);
}

export default function GetLectByUserData({url,inputdata,method}) {

  const [isLoading, setIsLoading] = useState(true);
  const [listaLecturas, setListaLecturas] = useState(null);
  const [error, setError] = useState(null);

  console.log(url)

  useEffect(() => {
    if (isLoading) {
      async function fetchData() {
        let response
        try {

          if(method === 'get'){
            console.log(".........")
            response = await fetch(
              url
            );
          }else if(method === 'post'){
            response = await fetch(
              url,
              inputdata
            );
          }
          
          

          if (response.ok) {
            const lista = await response.json();
            setListaLecturas(
                lista
            );
           
            
            setError(null);
            setIsLoading(false);
          } else {
            setError("Hubo un error al obtener la lista de lecturas.");
          }
        } catch (error) {
            console.log("kdlsamdadaldmklsamdlsadlakdsmaldalasld")
          console.log(error)
          setError("No pudimos hacer la solicitud para obtener las lecturas. Conéctate a la red.");
        }
      }
      fetchData();
    }
  }, [isLoading]);

  const listaLecturaLoader = () => {
    setIsLoading(true);
  };


  var da = []
  var transformedLectures = []
  if (error) { // ⬅️ mostramos el error (si es que existe)
    return (
         <div>Error: {error}</div>
    );
  }

  if (!listaLecturas) {
    return <div>No data available</div>;
  }else 

  try {
    da = JSON.parse(listaLecturas[0].body).Items
    var _transformedLectures = da;
    _transformedLectures.sort((a, b) => new Date(b.datetime_exam) - new Date(a.datetime_exam));
    transformedLectures = _transformedLectures
  } catch (error) {
    console.error(error.message);
  }
   

   console.log(da)
   return (
            <div className="p-10 bg-gray-100 ">
            {
              
              transformedLectures.map(card => {
                return (
                    <CardHistoryItem
                    title={card.lecture_id.split("-")[0]}
                    image={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.lecture_id}.jpg`}
                    aciertos={card.cantidad_acertadas}
                    preguntas={card.total_preguntas}
                    fecha={formatDateTime(card.datetime_exam)}
                    />
                )})
            }

            </div>

   )
}