import React, { useState } from 'react';

function LevelSelector({ onSelectionChange }) {
  const [nivel, setNivel] = useState('inicial');
  const [cantidad, setCantidad] = useState(2);
  const [grado, setGrado] = useState(2);
  const [rango, setRango] = useState('estudiantes');
  var targetGrado = grado
  var targetNivel = nivel
  var targetRango = rango
  const handleNivelChange = (event) => {
    const selectedNivel = event.target.value;
    targetNivel = selectedNivel
    setNivel(selectedNivel);
    // Actualizar la cantidad según el nivel seleccionado
    setGrado( grado )
    switch (selectedNivel) {
      case 'inicial':{
        setCantidad(2);
        targetGrado = targetGrado > 2 ? 1:targetGrado 
        setGrado( targetGrado );
        break;
      }
         
        
      case 'primaria':{
        setCantidad(6);
        targetGrado = targetGrado > 6 ? 1:targetGrado 
        setGrado( targetGrado );
        break;
      }
        
      case 'secundaria':{
        setCantidad(5);
        targetGrado = targetGrado > 5 ? 1:targetGrado 
        setGrado( targetGrado );
        break;
      }
        
      default:
        setCantidad(0);
    }
    onSelectionChange(targetNivel, targetGrado,targetRango);
  };
  const handleGradoChange = (event) => {
    var selectedGrado = event.target.value;
    targetGrado = selectedGrado
    setGrado(selectedGrado);
    onSelectionChange(nivel, targetGrado,targetRango);
  
  }

  const handleNivelRango = (event) => {
    var selectedRango = event.target.value;
    targetRango = selectedRango
    setRango(selectedRango);
    onSelectionChange(nivel, grado, targetRango);
  
  }

  return (
    <div className="container mt-4">
      <div className="row">
      <div className="col-md-6">
          <label htmlFor="rangoSelector">Dirigido a:</label>
          <select id="rangoSelector" className="form-control" value={targetRango} onChange={handleNivelRango}>
            <option value="estudiantes">Estudiantes</option>
            <option value="docentes">Docentes</option>
          </select>
        </div>
        <div className="col-md-6">
          <label htmlFor="nivelSelector">Nivel de Lectura:</label>
          <select id="nivelSelector" className="form-control" value={targetNivel} onChange={handleNivelChange}>
            <option value="inicial">Inicial</option>
            <option value="primaria">Primaria</option>
            <option value="secundaria">Secundaria</option>
          </select>
        </div>
        <div className="col-md-6">
          <label htmlFor="cantidadSelector">Grado:</label>
          <select id="cantidadSelector" className="form-control" value={targetGrado} onChange={handleGradoChange}>
            {[...Array(cantidad).keys()].map((value) => (
              <option key={value + 1} value={value + 1}>{value + 1}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default LevelSelector;
