import { Auth } from "aws-amplify"
import { useEffect, useState } from "react";



export default function Profile({usernhalim}) {

    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{

        function loadUser(){
            return Auth.currentUserInfo();
        }

        async function onLoad(){
            try{
                const user = await loadUser();
                setUser(user)
            }catch (e){
                alert(e)
            }
            setIsLoading(false)
        }
        onLoad();
    },[]);
    
    var datagrado = '#'
    console.log(usernhalim.attributes)
    if(user!=[]){
        if(user.attributes != null)
            datagrado = user.attributes['custom:Grado']
    }
    return <>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <p>{JSON.stringify(user)}</p>
        <p>{JSON.stringify(datagrado)}</p> */}
        <p><b>usuario:</b> {JSON.stringify(usernhalim.username)}</p>
        <p><b>Nombre:</b> {JSON.stringify(usernhalim.attributes['name'])}</p>
        <p><b>Nickname:</b> {JSON.stringify(usernhalim.attributes['nickname'])}</p>
        <p><b>Apellido:</b> {JSON.stringify(usernhalim.attributes['family_name'])}</p>
        {/* <p><b>email:</b> {JSON.stringify(usernhalim.attributes['email'])}</p> */}
        <p><b>Institución:</b> {JSON.stringify(usernhalim.attributes['custom:Colegio'])}</p>
        {/* <p><b>Grado:</b> {JSON.stringify(usernhalim.attributes['custom:Grado'])}</p> */}
        <p><b>Nivel:</b> {JSON.stringify(usernhalim.attributes['custom:Nivel'])}</p>
        <p><b>Categoria:</b> {JSON.stringify(usernhalim.attributes['custom:categoria_usuario'])}</p>
    </>
    
}

