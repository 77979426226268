import * as React from "react";
import { motion } from "framer-motion";
import { MenuItemSignOut,MenuItemHome,MenuItemMiProgreso, MenuItemMisLecturas, MenuItemMiPerfil, MenuItemMiScore, MenuCreateLecture, MenuItemMiEstadistica} from "./MenuItem.tsx";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = ({SignOut, username}) => (
  
  <motion.ul variants={variants}>
    <MenuItemMiPerfil username={username}></MenuItemMiPerfil>
    
    <MenuItemMiProgreso></MenuItemMiProgreso>
    <MenuItemMisLecturas></MenuItemMisLecturas>
    <MenuItemMiScore></MenuItemMiScore>
    <MenuItemMiEstadistica></MenuItemMiEstadistica>
    <MenuCreateLecture></MenuCreateLecture>
    <MenuItemHome></MenuItemHome>
    <MenuItemSignOut SignOut={SignOut}></MenuItemSignOut>
    
    
  </motion.ul>
);

