function GlobalProgress({ user}) {
    return (
        <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <iframe
        title="Google Data Studio Report"
        src="https://lookerstudio.google.com/embed/reporting/7c34d261-ea9b-462b-b3b6-e4f1713ba39b/page/p_maj5engbkd"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          position: 'absolute',
          top: 0,
          left: 0
        }}
        allowFullScreen
      ></iframe>
    </div>
    )
    return (<>
        <iframe width="600" height="450" src="https://lookerstudio.google.com/embed/reporting/7c34d261-ea9b-462b-b3b6-e4f1713ba39b/page/p_q1prhtqqjd" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
    </>)
}

export default GlobalProgress
